<template>
  <b-card>

    <div v-if="questionData && movieData && actors && movieFrames">

      <h2>
        {{ movieData.title_ru }} ({{ movieData.year }})
        <small>
          {{ movieData.title_en }}
        </small>
      </h2>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1" @submit.prevent="onSubmit">

        <b-row class="mt-1">
          <b-col lg="3">
            <b-list-group>
              <b-list-group-item
                  v-for="actor in actors"
                  :key="actor.staffId"
                  :variant="(actor.description === selectedCharacter) ? 'success' : ''"
                  @click="selectItem(actor)"
                  button
              >
                {{ actor.description }} / {{ actor.nameRu }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col lg="9">
            <div v-if="selectedFrame">
              <b-img
                  :src="selectedFrame.imageUrl"
                  fluid
                  thumbnail
              />
            </div>

            <swiper
                class="swiper"
                :options="swiperOption"
            >
              <swiper-slide
                  v-for="(frame,index) in movieFrames"
                  :key="index"
                  class="rounded swiper-shadow"
              >
                <b-img
                    :src="frame.previewUrl"
                    fluid
                    @click="selectFrame(frame)"
                    :thumbnail="(selectedFrameId === frame.index)"
                />
              </swiper-slide>

              <!-- Add Arrows -->
              <div
                  slot="button-next"
                  class="swiper-button-next"
              />
              <div
                  slot="button-prev"
                  class="swiper-button-prev"
              />
              <div
                  slot="pagination"
                  class="swiper-pagination"
              />
            </swiper>
          </b-col>
        </b-row>

        <!-- Form: Кадры -->
        <b-row class="mt-1">

          <!-- Field: Кадры -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >

          </b-col>
        </b-row>


        <!-- Form: Ответы-Комменты -->
        <b-row class="mt-1">

          <!-- Field: Answer Comment -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Комментарий к ответу"
                label-for="answer-comment"
            >
              <b-form-textarea
                  rows="3"
                  max-rows="6"
                  id="answer-comment"
                  v-model="questionData.answer_comment"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Comment -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Комментарий"
                label-for="comment"
            >
              <b-form-input
                  id="comment"
                  v-model="questionData.comment"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Comment -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Ответ"
                label-for="answer"
            >
              <b-form-input
                  id="answer"
                  v-model="questionData.answer"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
                variant="success"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="isSaving"
            >
              <span v-if="isSaving">
                <b-spinner small/>
                Сохраняем...
              </span>
              <span v-else>
                Добавить
              </span>
            </b-button>


            <b-button
                variant="outline-secondary"
            >
              Дальше
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormTextarea,
  BImg,
  BAvatar,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import questionStoreModule from '../questionStoreModule'
import moviesStoreModule from '../../database/movieStoreModule'
import vSelect from "vue-select";
import {avatarText} from '@core/utils/filter'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormTextarea,
    BImg,
    BAvatar,
    vSelect,
    BSpinner,
    Swiper,
    SwiperSlide,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 7,
        slidesPerColumn: 2,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  setup(props) {
    const questionData = ref({})
    const movieData = ref({})
    const movieFrames = ref([])
    const actors = ref([])
    const selectedCharacter = ref(null)
    const selectedFrameId = ref(-1)
    const selectedFrame = ref(null)
    const addedQuestion = ref(null)
    const isSaving = ref(false)


    const QUESTIONS_APP_STORE_MODULE_NAME = 'app-questions'
    const MOVIES_STORE_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(QUESTIONS_APP_STORE_MODULE_NAME)) store.registerModule(QUESTIONS_APP_STORE_MODULE_NAME, questionStoreModule)
    if (!store.hasModule(MOVIES_STORE_MODULE_NAME)) store.registerModule(MOVIES_STORE_MODULE_NAME, moviesStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUESTIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTIONS_APP_STORE_MODULE_NAME)
      if (store.hasModule(MOVIES_STORE_MODULE_NAME)) store.unregisterModule(MOVIES_STORE_MODULE_NAME)
    })

    store.dispatch('movies/fetchMovieWithStaff', {id: router.currentRoute.params.movie_id})
        .then(response => {
          movieData.value = response.data.data
          actors.value = movieData.value.actors.slice(0, 10)
          questionData.value.theme_template = router.currentRoute.name
        })

    store.dispatch('movies/fetchFrames', { id: router.currentRoute.params.movie_id })
        .then(response => {
          movieFrames.value = response.data
        })

    const onSubmit = () => {
      isSaving.value = true
      questionData.value.movies = [{id:movieData.value.id}];
      questionData.value.question_url = selectedFrame.value.imageUrl;
      store.dispatch('app-questions/addQuestion', {
        question: questionData.value
      })
          .then(response => {
            addedQuestion.value = response.data.data
            isSaving.value = false
          })
    }

    const selectItem = (actor) => {
      actor.selected = !actor.selected
      selectedCharacter.value = actor.description
      questionData.value.answer = `${actor.description} / ${movieData.value.title_ru}`
    }

    const selectFrame = (frame) => {
      selectedFrame.value = frame
      selectedFrameId.value = frame.index
    }

    return {
      questionData,
      movieData,
      movieFrames,
      actors,
      selectedCharacter,
      selectedFrameId,
      selectedFrame,
      addedQuestion,
      isSaving,
      onSubmit,
      selectItem,
      selectFrame,

      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.swiper {
  ::v-deep .swiper-wrapper {
    flex-direction: row !important;
  }

  .swiper-slide {
    margin-top: 30px;
  }
}
</style>